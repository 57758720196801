export const IMAGE = "image";
export const VIDEO = "video";
export const PDF = "pdf";

export const PLANNING = "planning";
export const RECURRING_POSTS = "recurring posts";
export const CALENDAR = "calender";
export const IG_FEED_PREVIEW = "instagram feed preview";
export const DELAY = "publication delay";

export const SELECT_VIDEO = "select from video";
export const UPLOAD_FILE = "upload file";
export const MEDIA_LIBRARY = "media library";
export const POST_PREVIEW = "post preview";
export const IG_GRID_PREVIEW = "instagram grid preview";

export const SOURCE_IMAGE = "source image";
export const EDITED_IMAGE = "edited image";
export const THUMBNAIL_IMAGE = "thumbnail image";
export const FULL_EDITOR_IMAGE = "full editor image";

export const EDITOR_VIEW = "editor_view";
export const UPLOAD_VIEW = "upload_view";

export const MEDIA_TYPE_VIDEO = "video";
export const MEDIA_TYPE_IMAGE = "image";
export const MEDIA_TYPE_PDF = "application/pdf";
export const MEDIA_TYPE_DOC = "doc";

export const EXTERNAL = "external";
export const LOCAL = "local";

export const VIEW_STATUS = {
  PLANNING,
  RECURRING_POSTS,
  CALENDAR,
  IG_FEED_PREVIEW,
  DELAY,
};

export const THUMBNAIL_SOURCE = {
  SELECT_VIDEO,
  UPLOAD_FILE,
  MEDIA_LIBRARY,
};

export const THUMBNAIL_PREVIEWS = {
  POST_PREVIEW,
  IG_GRID_PREVIEW,
};

export const BTN_SAVE_AS_DRAFT = "save_as_draft_btn";
export const BTN_PLAN_PUBLICATION = "plan_publication_btn";
export const BTN_PUBLISH_NOW = "publish_now_btn";
export const BTN_SAVE_FOR_APPROVAL = "save_for_approval_btn";
export const BTN_SAVE_FOR_APPROVAL_CLOUD = "save_for_approval_cloud_btn";
export const BTN_PUBLISH_AND_CONFIGURE = "publish_and_configure_btn";
export const BTN_PLAN_AND_CONFIGURE = "plan_and_configure_btn";
export const BTN_SAVE = "save_btn";
export const POST_ACTION_SAVE_AS_DRAFT_ID = 1;
export const POST_ACTION_PUBLISH_NOW_ID = 2;
export const POST_ACTION_SCHEDULE_ID = 3;
export const POST_ACTION_PUBLISH_AND_CONFIGURE_ID = 4;
export const POST_ACTION_PLAN_AND_CONFIGURE_ID = 5;
export const POST_ACTION_SAVE_FOR_APPROVAL_ID = 6;
export const POST_ACTION_SAVE_ID = 7;

export const ACTIONS_VALUES_MAP = {
  [BTN_SAVE_AS_DRAFT]: POST_ACTION_SAVE_AS_DRAFT_ID,
  [BTN_PUBLISH_NOW]: POST_ACTION_PUBLISH_NOW_ID,
  [BTN_PLAN_PUBLICATION]: POST_ACTION_SCHEDULE_ID,
  [BTN_SAVE_FOR_APPROVAL]: POST_ACTION_SAVE_FOR_APPROVAL_ID,
  [BTN_PUBLISH_AND_CONFIGURE]: POST_ACTION_PUBLISH_AND_CONFIGURE_ID,
  [BTN_PLAN_AND_CONFIGURE]: POST_ACTION_PLAN_AND_CONFIGURE_ID,
  [BTN_SAVE]: POST_ACTION_SAVE_ID,
  [BTN_SAVE_FOR_APPROVAL_CLOUD]: POST_ACTION_SAVE_FOR_APPROVAL_ID,
};

export const ACTIONS_THAT_REQUIRE_PERMISSION = [
  BTN_PUBLISH_AND_CONFIGURE,
  BTN_PLAN_AND_CONFIGURE,
  BTN_PLAN_PUBLICATION,
  BTN_PUBLISH_NOW,
];

export const isTextLengthValidKey = "isTextLengthValid";
export const PostHasTextOrMediaKey = "PostHasTextOrMedia";
export const isPostPublicationDateSet = "isPostPublicationDateSet";
export const mediaIsLoadingKey = "mediaIsLoadingKey";
export const youtubeTitleLengthKey = "youtubeTitleLengthKey";
export const validateYoutubeTitleExistsKey = "validateYoutubeTitleExistsKey";
export const oneMediaShouldBeSelectedKey = "oneMediaShouldBeSelectedKey";
export const isGeneralOptionsUrlsValidKey = "isGeneralOptionsUrlsValidKey";
export const isGeneralLinksHasTitlesKey = "isGeneralLinksHasTitlesKey";
export const generalTitleIsRequiredKey = "generalTitleIsRequiredKey";
export const validateLinkedinDocumentTitleExistsKey =
  "validateLinkedinDocumentTitleExistsKey";
export const validateDateIsInFutureKey = "validateDateIsInFutureKey";

export const validatorsWithPlatform = [oneMediaShouldBeSelectedKey];
export const PREVIEW_TYPE_STANDARD = "standard";
export const PREVIEW_TYPE_COMPACT = "compact";

export const POST_STATUS_PROPOSED_BY_EXTERNAL_USER = 1;
export const POST_STATUS_DRAFT = 2;
export const POST_STATUS_WAITING_FOR_APPROVAL = 3;
export const POST_STATUS_READY = 4;
export const POST_STATUS_COPYING = 5;
export const POST_STATUS_LABELS = {
  [POST_STATUS_PROPOSED_BY_EXTERNAL_USER]: "proposed_by_external_user",
  [POST_STATUS_DRAFT]: "draft",
  [POST_STATUS_WAITING_FOR_APPROVAL]: "waiting_for_approval",
  [POST_STATUS_READY]: "ready",
  [POST_STATUS_COPYING]: "copying",
  null: "new",
};
