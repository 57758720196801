const HASHTAG_REGEX = /(^| {1})(#[äÄüÜöÖß\w\d]+)/gim;
const MENTION_REGEX = /(@[äÄüÜöÖß\w\d-\.-_]+)/gi;
const MENTION_REGEX_QUILL_CONFIG =
  /^[A-Za-zÅÄÖÜåäöü\d.\w-|&+$]*(?:\s[A-Za-zÅÄÖÜåäöü\d.\w-|&+$]+)*$/i;
const URL_REGEX =
  /(^| {1})((?:(?:(?:http|https):(?:\/\/)?)(?:[\-;:&=\+\$,\w]+)?[A-Za-z0-9\.\-]+\.\w{2,}|(?:www\.)[A-Za-z0-9\.\-]+\.\w{2,})(?:\/{0,1}[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*)?)/gim;

import Delta from "quill-delta";
import axiosClient from "../../../../utils/axios";
export const QUILL_API_ACTION_ADD_EMOJI = "addEmojiViaApi";

export function highlightQuillInput(quillInstance) {
  let contents = quillInstance.getContents();
  let blobs = contents.ops;

  let opsArray = [];

  let cursorPosition = quillInstance.getSelection();

  // build array with blobs only being mention or text
  for (let i = 0; i < blobs.length; i++) {
    let inserted = blobs[i].insert;
    let isMention = typeof inserted !== "string";
    if (isMention) {
      opsArray.push(JSON.parse(JSON.stringify(blobs[i])));
    } else {
      let last_data = opsArray[opsArray.length - 1];
      if (last_data !== undefined) {
        let last_data_is_mention = typeof last_data.insert !== "string";
        if (last_data_is_mention) {
          opsArray.push({
            insert: "",
          });
        }
      } else {
        opsArray.push({
          insert: "",
        });
      }
      last_data = opsArray[opsArray.length - 1];

      last_data.insert += inserted;
    }
  }

  // correct newline characters
  let opsArrayWithCorrectNewline = [];
  for (let i = 0; i < opsArray.length; i++) {
    let blob = opsArray[i];
    let inserted = blobs[i].insert;
    let isMention = typeof inserted !== "string";
    if (isMention) {
      opsArrayWithCorrectNewline.push(blob);
    } else {
      let betweenNewLines = inserted.split("\n");
      for (let j = 0; j < betweenNewLines.length; j++) {
        let t = betweenNewLines[j];
        if (t.length === 0) continue;
        opsArrayWithCorrectNewline.push({
          insert: betweenNewLines[j],
        });
        opsArrayWithCorrectNewline.push({
          insert: "\n",
        });
      }
    }
  }

  // highlight hashtags
  opsArrayWithCorrectNewline = opsArray;
  let opsArrayWithFormatting = [];
  for (let i = 0; i < opsArrayWithCorrectNewline.length; i++) {
    let blob = opsArrayWithCorrectNewline[i];
    let inserted = opsArrayWithCorrectNewline[i].insert;
    let isMention = typeof inserted !== "string";
    if (isMention) {
      opsArrayWithFormatting.push(blob);
    } else {
      for (let str of inserted.split(HASHTAG_REGEX)) {
        let isEmpty = str === "";
        if (isEmpty) continue;

        let isHashtag = str.match(HASHTAG_REGEX) !== null;

        if (isHashtag) {
          opsArrayWithFormatting.push({
            attributes: {
              bold: true,
              color: "#3699ff",
            },
            insert: str,
          });
        } else {
          opsArrayWithFormatting.push({
            attributes: {
              bold: false,
              color: "black",
            },
            insert: str,
          });
        }
      }
    }
  }

  // highlight url
  let opsArrayWithFormatting2 = [];
  for (let i = 0; i < opsArrayWithFormatting.length; i++) {
    let blob = opsArrayWithFormatting[i];
    let inserted = blob.insert;
    let isMention = typeof inserted !== "string";
    if (isMention) {
      opsArrayWithFormatting2.push(blob);
    } else {
      for (let str of inserted.split(URL_REGEX)) {
        let isEmpty = str === "" || str === undefined;
        if (isEmpty) continue;

        let attributes_not_set = blob.attributes === undefined;
        if (attributes_not_set) continue;
        let attrs = blob.attributes;
        let is_already_marked = attrs.color != "black";
        if (is_already_marked) {
          opsArrayWithFormatting2.push(blob);
          break;
        }

        let isHashtag = str.match(URL_REGEX) !== null;

        if (isHashtag) {
          opsArrayWithFormatting2.push({
            attributes: {
              bold: false,
              color: "#3699ff",
              link: true,
            },
            insert: str,
          });
        } else {
          opsArrayWithFormatting2.push({
            attributes: {
              bold: false,
              color: "black",
            },
            insert: str,
          });
        }
      }
    }
  }
  quillInstance.setContents(new Delta({ ops: opsArrayWithFormatting2 }, "api"));
  if (cursorPosition !== null) {
    quillInstance.setSelection(cursorPosition.index + cursorPosition.length);
  }
}

export function setCursorPositionForQuill(quillInstance) {
  let selection = quillInstance.getSelection();
  let cursorPosition;
  if (selection) {
    cursorPosition = selection.index + selection.length;
  } else {
    cursorPosition = removeTrailingNewlineCharacter(
      quillInstance.getText()
    ).length;
  }
  quillInstance.container.dataset.cursorPosition = cursorPosition;
}

function removeTrailingNewlineCharacter(text) {
  return text.replace(/\n$/, "");
}

export const highlightFunctionAndQuillCopy = (
  allPlatformTextQuillInstance,
  seperateQuill
) => {
  let contents = allPlatformTextQuillInstance.getContents();
  let blobs = contents.ops;
  highlightQuillInput(allPlatformTextQuillInstance);

  for (let j = 0; j < seperateQuill.length; j++) {
    let quillInstance = seperateQuill[j];
    let platform = quillInstance.container.dataset.platform;

    let new_content = [];
    for (let i = 0; i < blobs.length; i++) {
      let blob = blobs[i];
      let inserted = blobs[i].insert;
      let isMention = typeof inserted !== "string";

      if (isMention) {
        let mentionBelongsToPlatform =
          inserted.mention.platform.indexOf(platform) > -1;
        if (mentionBelongsToPlatform) {
          new_content.push(blob);
        } else {
          //mention does not belong to this platform -> we need handle the auto included space properly
          let nextBlob = blobs[i + 1] || {};
          let nextBlobInsert = nextBlob.insert || "";
          let secondNextBlob = blobs[i + 2] || {};
          let secondNextBlobInsert = secondNextBlob.insert || "";
          let nextBlobIsMention = typeof nextBlobInsert !== "string";
          let secondNextBlobIsMention =
            typeof secondNextBlobInsert !== "string";
          if (!nextBlobIsMention) {
            let leadingCharIsWhiteSpace = nextBlobInsert.slice(0, 1) === " ";
            if (leadingCharIsWhiteSpace) {
              //remove leading space from next blob
              new_content.push({ insert: nextBlobInsert.slice(1) });
            } else {
              new_content.push({ insert: nextBlobInsert });
            }
            // we modified next blob, let's skip the next iteration
            i++;
          }
        }
      } else {
        new_content.push(blob);
      }
    }
    quillInstance.setContents(new_content, "user");
    //quillInstance.insertText(quillInstance.getLength(), '', 'user') // force run of highlighting
  }

  //exportQuillToTextField(allPlatformTextQuillInstance)
};

const linkPreviewCache = {};
const LOADING_LINK_PREVIEW = "loadingLink";
const sleep = (ms) => new Promise((res) => setTimeout(res, ms));
const LINK_PREVIEW_API_URL = "/api/v1/posts/link_preview/";
function buildLinkPreviewApiUrl(url) {
  return `${LINK_PREVIEW_API_URL}?url=${encodeURIComponent(url)}`;
}
export function fetchLinkPreview(url) {
  return new Promise(async (resolve, reject) => {
    const urlIsInCache = Object.keys(linkPreviewCache).indexOf(url) !== -1;
    let data = {};

    if (urlIsInCache) {
      data = linkPreviewCache[url];

      // when an url is entered the preview data needs to be fetched for every platform.
      // If one request is already being send to the backend, the other requests will
      // wait for data to be loaded into the cache and then use the cached data. This wax
      // is to prevent multiple requests to the backend for the same url.
      const dataIsStillLoading = data === LOADING_LINK_PREVIEW;
      if (dataIsStillLoading) {
        for (let i = 0; i < 2000; i++) {
          await sleep(100);
          data = linkPreviewCache[url];
          if (data !== LOADING_LINK_PREVIEW) {
            break;
          }
        }
      }
    } else {
      linkPreviewCache[url] = LOADING_LINK_PREVIEW;
      let retry = 0;
      const MAX_RETRIES = 6;
      let err = undefined;
      while (retry < MAX_RETRIES) {
        err = undefined;
        try {
          //data = await (await fetch(buildLinkPreviewApiUrl(url))).json()
          data = await axiosClient.get(buildLinkPreviewApiUrl(url));
          break;
        } catch (e) {
          err = e;
          console.log("Failed to fetch preview api - retrying", err);
          retry++;
        }
      }

      if (err) {
        console.error(
          "Failed to fetch preview api - not retrying anymore",
          err
        );
        //Sentry.captureException(err)
      }

      linkPreviewCache[url] = data;
    }

    resolve(data);
  });
}
export const getUrlFromText = (text) => {
  const urlRegex =
    /((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/;

  const urls = text.match(urlRegex);
  if (!urls) {
    return null;
  }
  return urls[0];
};

export const getUrlPreview = async (text) => {
  const url = getUrlFromText(text);
  if (!url) {
    return null;
  }
  const response: any = await fetchLinkPreview(url);
  if (response.data.count === 0) {
    return { error: true };
  }
  return { ...response.data.results[0], error: false };
};

export function getTextFromQuill(quillInstance, excludeMentions) {
  if (!quillInstance) {
    return;
  }
  let blobs = quillInstance.getContents();
  let text = "";
  for (let d of blobs.ops) {
    let inserted = d.insert;
    let isMention = typeof inserted !== "string";
    if (isMention) {
      if (excludeMentions) {
        continue;
      }
      text += inserted.mention.show_denotation_char === "true" ? "@" : "";
      text += inserted.mention.display_name;
    } else {
      text += inserted;
    }
  }
  text = text.replace(/\n$/, "");
  return text;
}
