import { add } from "date-fns";
import {
  FACEBOOK,
  GENERIC,
  GOOGLE,
  INSTAGRAM,
  LINKEDIN_BUSINESS_PAGE,
  LINKEDIN_PERSONAL_ACCOUNT,
  PINTEREST,
  TIKTOK_BUSINESS_PAGE,
  TIKTOK_PERSONAL_ACCOUNT,
  TWITTER,
  WHATSAPP,
  YOUTUBE,
} from "../../../config/constants";
import { FacebookAdditionalOptions } from "../components/postAdditionalOptions/facebookAdditionalOptions";
import { InstagramAdditionalOptions } from "../components/postAdditionalOptions/instagramAdditionalOptions";
import { LinkedinAdditionalOptions } from "../components/postAdditionalOptions/linkedinAdditionalOptions";
import { GoogleAdditionalOptions } from "../components/postAdditionalOptions/googleAdditionalOptions";
import { YoutubeAdditionalOptions } from "../components/postAdditionalOptions/youtubeAdditionalOptions";

export const MAX_FILE_COUNT = 10;

export const MAX_IMAGE_WIDTH = 1920;
export const MAX_IMAGE_HEIGHT = 1920;
export const IMAGE_QUALITY = 1.0;
export const POST_TYPE_POST_KEY = "postTypePost";
export const POST_TYPE_KEY = "postType";

export const POST_TYPE_STORY_KEY = "postTypeStory";
export const POST_TYPE_REEL_KEY = "postTypeReel";
export const POST_TYPE_SHORT_VIDEO_KEY = "postTypeShortVideo";
export const POST_TYPE_VIDEO_KEY = "postTypeVideo";
export const POST_TYPE_MESSAGE = "postTypeMessage";
export const POST_TYPE_STATUS = "postTypeStatus";
// general post types
export const POST_TYPE_GALLERY_IMAGE = "galleryImage";
export const POST_TYPE_INFORMATION_NEWS = "informationNews";
export const POST_TYPE_PRODUCT = "product";
export const POST_TYPE_OFFER = "offer";

export const POST_TYPE_TO_PREFIX = {
  [POST_TYPE_OFFER]: "offer",
  [POST_TYPE_PRODUCT]: "product",
  [POST_TYPE_INFORMATION_NEWS]: "information_news",
};
export const POST_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  TEXT: "text",
  REEL: "reel",
  STORY: "story",
  //MULTI_IMAGE: "multi_image",
  POST_TYPE_SHORT_VIDEO_KEY: "short_video",
};

export const POST_TYPES_TITLES = {
  [POST_TYPE_POST_KEY]: "POST_TYPES_TITLES.Post",
  [POST_TYPE_STORY_KEY]: "POST_TYPES_TITLES.POST_TYPES_TITLES.Story",
  [POST_TYPE_REEL_KEY]: "POST_TYPES_TITLES.Reel",
  [POST_TYPE_SHORT_VIDEO_KEY]: "POST_TYPES_TITLES.Short",
  [POST_TYPE_VIDEO_KEY]: "POST_TYPES_TITLES.Video",
  [POST_TYPE_MESSAGE]: "POST_TYPES_TITLES.Message",
  [POST_TYPE_STATUS]: "POST_TYPES_TITLES.Status",
};

export const POST_TYPE_DESCRIPTIONS = {
  [POST_TYPE_POST_KEY]: "POST_TYPE_DESCRIPTIONS.post",
  [POST_TYPE_STORY_KEY]: "POST_TYPE_DESCRIPTIONS.story",
  [POST_TYPE_REEL_KEY]: "POST_TYPE_DESCRIPTIONS.reel",
  [POST_TYPE_SHORT_VIDEO_KEY]: "POST_TYPE_DESCRIPTIONS.short",
  [POST_TYPE_VIDEO_KEY]: "POST_TYPE_DESCRIPTIONS.video",
  [POST_TYPE_MESSAGE]: "POST_TYPE_DESCRIPTIONS.message",
  [POST_TYPE_STATUS]: "POST_TYPE_DESCRIPTIONS.status",
};
export const POST_TYPE_CHOICE_GALLERY_IMAGE = 1;
export const POST_TYPE_CHOICE_POST = 2;
export const POST_TYPE_CHOICE_LINK = 3;
export const POST_TYPE_CHOICE_INFORMATION_NEWS = 4;
export const POST_TYPE_CHOICE_PRODUCT = 5;
export const POST_TYPE_CHOICE_OFFER = 6;

export const POST_TYPE_CHOICES_MAP = {
  [POST_TYPE_CHOICE_GALLERY_IMAGE]: POST_TYPE_GALLERY_IMAGE,
  [POST_TYPE_CHOICE_POST]: POST_TYPE_KEY,
  [POST_TYPE_CHOICE_INFORMATION_NEWS]: POST_TYPE_INFORMATION_NEWS,
  [POST_TYPE_CHOICE_PRODUCT]: POST_TYPE_PRODUCT,
  [POST_TYPE_CHOICE_OFFER]: POST_TYPE_OFFER,
};

const CROP_RATIO_16_9 = "16:9";
const CROP_RATIO_16_10 = "16:10";
const CROP_RATIO_9_16 = "9:16";
const CROP_RATIO_191_1 = "1.91:1";
const CROP_RATIO_1_1 = "1:1";
const CROP_RATIO_4_5 = "4:5";
const CROP_RATIO_5_4 = "5:4";
export const CROP_RATIO_CUSTOM = "0:0";

const VIDEO_MIN_ASPECT_RATIO = "minAspectRatio";
const VIDEO_MAX_ASPECT_RATIO = "maxAspectRatio";

export const CROP_RATIOS = {
  [CROP_RATIO_4_5]: {
    width: 4,
    height: 5,
    icon: "fa-thin fa-rectangle-portrait",
  },
  [CROP_RATIO_5_4]: {
    width: 5,
    height: 4,
    icon: "fa-thin fa-rectangle-landscape",
  },
  [CROP_RATIO_16_9]: {
    width: 16,
    height: 9,
    icon: "fa-thin fa-rectangle-landscape",
  },
  [CROP_RATIO_16_10]: {
    width: 16,
    height: 10,
    icon: "fa-thin fa-rectangle-landscape",
  },
  [CROP_RATIO_191_1]: {
    width: 1.91 * 100,
    height: 100,
    icon: "fa-thin fa-rectangle-landscape",
  },
  [CROP_RATIO_9_16]: {
    width: 9,
    height: 16,
    icon: "fa-thin fa-rectangle-portrait",
  },
  [CROP_RATIO_1_1]: {
    width: 1,
    height: 1,
    icon: "fa-thin fa-square",
  },
  [CROP_RATIO_CUSTOM]: {
    width: 0,
    height: 0,
    icon: "fa-thin fa-crop",
  },
};

const ASPECT_RATIOS_AS_NUMBERS = Object.values(CROP_RATIOS).map(
  ({ width, height }) => width / height
);
const genericPlatformPostTypeConfig = [
  {
    typeKey: POST_TYPE_POST_KEY,
    additionalOptions: {
      enabled: false,
    },
    enabled: true,
    mediaTypes: {
      video: {
        enabled: true,
        maxFiles: 1,
        maxFileSizeInBytes: 1024 * 1024 * 300,
        maxAudioChannels: 2,
        // some other limitations
      },
      image: {
        enabled: true,
        maxFiles: 10,
        maxFileSizeInBytes: 1024 * 1024 * 50,
        cropSuggestions: [
          CROP_RATIO_9_16,
          CROP_RATIO_4_5,

          CROP_RATIO_1_1,
          CROP_RATIO_16_9,
          CROP_RATIO_CUSTOM,
        ],
        defaultCropRatio: CROP_RATIO_9_16,

        // some other limitations
      },
    },
    allowMixedMediaMultiFile: false,
    maxTextLength: 33000,
  },
].map((postType) => ({
  ...postType,
  title: POST_TYPES_TITLES[postType.typeKey],
  description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
}));

export const platformFeatures = {
  [FACEBOOK]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        additionalOptions: {
          enabled: true,
          component: FacebookAdditionalOptions,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 2000,
            maxAudioChannels: 2,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 10,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            maxDurationInSeconds: 60 * 60 * 5,
            cropSuggestions: [
              CROP_RATIO_9_16,
              CROP_RATIO_4_5,

              CROP_RATIO_1_1,
              CROP_RATIO_16_9,
              CROP_RATIO_CUSTOM,
            ],
            defaultCropRatio: CROP_RATIO_4_5,

            // some other limitations
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 33000,
      },
      {
        typeKey: POST_TYPE_REEL_KEY,
        additionalOptions: {
          enabled: true,
          component: FacebookAdditionalOptions,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 2000,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 90,
          },
          image: {
            enabled: false,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 33000,
        dataFieldEnable: ["is_facebook_reel"],
      },
      {
        typeKey: POST_TYPE_STORY_KEY,

        enabled: true,
        additionalOptions: {
          enabled: true,
          component: FacebookAdditionalOptions,
        },
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 100,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60,
            allowedAspectRatios: {
              [VIDEO_MIN_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
              [VIDEO_MAX_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
            },
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 1,
            cropSuggestions: [CROP_RATIO_9_16, CROP_RATIO_CUSTOM],
            defaultCropRatio: CROP_RATIO_9_16,
            // some other limitations
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 33000,
        dataFieldEnable: ["is_facebook_story"],
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
    [POST_TYPE_GALLERY_IMAGE]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        additionalOptions: {
          enabled: false,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: false,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 2000,
            maxAudioChannels: 1,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 10,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            cropSuggestions: [
              CROP_RATIO_16_9,
              CROP_RATIO_9_16,
              CROP_RATIO_1_1,
              CROP_RATIO_4_5,
            ],
            defaultCropRatio: CROP_RATIO_16_9,
            // some other limitations
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 33000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [INSTAGRAM]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        additionalOptions: {
          enabled: true,
          component: InstagramAdditionalOptions,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 10,
            maxAudioChannels: 2,
            maxFileSizeInBytes: 1024 * 1024 * 1000,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 15,

            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 10,
            cropSuggestions: [
              CROP_RATIO_4_5,
              CROP_RATIO_1_1,
              CROP_RATIO_16_9,
              CROP_RATIO_16_10,
              CROP_RATIO_CUSTOM,
            ],
            // Insta only setting. All images that are not one of these aspect ratios will be published as a square
            // image with padding. If the selected aspect ratio is not within this list, we wanna add padding to the
            cropsWithoutPadding: [
              CROP_RATIO_191_1,
              CROP_RATIO_4_5,
              CROP_RATIO_1_1,
            ],
            defaultCropRatio: CROP_RATIO_1_1,
            notAllowedCropHandling: {
              // fill up with padding if a custom crop is selected or it does not match the allowed aspect ratio
              addPAdding: true,
              // default aspect ratio to be used if the crop is not allowed
              defaultAspectRatio: CROP_RATIOS[CROP_RATIO_1_1],
            },

            // some other limitations
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },
        allowMixedMediaMultiFile: true,
        maxTextLength: 2200,
      },
      {
        typeKey: POST_TYPE_REEL_KEY,
        enabled: true,
        additionalOptions: {
          enabled: true,
          component: InstagramAdditionalOptions,
        },
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxAudioChannels: 2,
            maxFileSizeInBytes: 1024 * 1024 * 1000,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 15,
            // some other limitations
          },
          image: {
            enabled: false,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 2200,
        dataFieldEnable: ["is_instagram_reel"],
      },
      {
        typeKey: POST_TYPE_STORY_KEY,
        enabled: true,
        additionalOptions: {
          enabled: true,
          component: InstagramAdditionalOptions,
        },
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 100,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60,
            allowedAspectRatios: {
              [VIDEO_MIN_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
              [VIDEO_MAX_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
            },
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 1,
            maxAudioChannels: 2,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            cropSuggestions: [CROP_RATIO_9_16, CROP_RATIO_CUSTOM],
            defaultCropRatio: CROP_RATIO_9_16,
            cropsWithoutPadding: [CROP_RATIO_9_16],
            notAllowedCropHandling: {
              addPAdding: true,
              defaultAspectRatio: CROP_RATIOS[CROP_RATIO_1_1],
            },
            // some other limitations
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 2200,
        dataFieldEnable: ["is_instagram_story"],
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [GOOGLE]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        additionalOptions: {
          enabled: true,
          component: GoogleAdditionalOptions,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: false,
            maxFiles: 1,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            cropSuggestions: [
              CROP_RATIO_16_9,
              CROP_RATIO_9_16,
              CROP_RATIO_1_1,
              CROP_RATIO_4_5,
            ],
            defaultCropRatio: CROP_RATIO_16_9,
            // some other limitations
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 1500,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
    [POST_TYPE_GALLERY_IMAGE]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        additionalOptions: {
          enabled: false,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: false,
            maxFiles: 1,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            cropSuggestions: [
              CROP_RATIO_16_9,
              CROP_RATIO_9_16,
              CROP_RATIO_1_1,
              CROP_RATIO_4_5,
            ],
            defaultCropRatio: CROP_RATIO_16_9,
            // some other limitations
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 1500,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [TWITTER]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxDurationInSeconds: 60 * 10,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 4,
            maxFileSizeInBytes: 1024 * 1024 * 512,
            cropSuggestions: [
              CROP_RATIO_4_5,
              CROP_RATIO_1_1,
              CROP_RATIO_16_9,
              CROP_RATIO_16_10,

              CROP_RATIO_CUSTOM,
            ],
            defaultCropRatio: CROP_RATIO_CUSTOM,
            // some other limitations
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 280,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [LINKEDIN_PERSONAL_ACCOUNT]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        enabled: true,
        additionalOptions: {
          enabled: true,
          component: LinkedinAdditionalOptions,
        },
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 2000,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 15,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 10,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            cropSuggestions: [
              CROP_RATIO_16_9,
              CROP_RATIO_9_16,
              CROP_RATIO_1_1,
              CROP_RATIO_4_5,
            ],
            defaultCropRatio: CROP_RATIO_16_9,
            // some other limitations
          },
          pdf: {
            enabled: true,
            maxFiles: 1,
            maxPageNumbers: 300,
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 3000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [LINKEDIN_BUSINESS_PAGE]: {
    [POST_TYPE_KEY]: [
      {
        additionalOptions: {
          enabled: true,
          component: LinkedinAdditionalOptions,
        },
        typeKey: POST_TYPE_POST_KEY,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 2000,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 15,
            // some other limitations
          },
          image: {
            enabled: true,
            maxFiles: 10,
            maxFileSizeInBytes: 1024 * 1024 * 50,
            cropSuggestions: [
              CROP_RATIO_16_9,
              CROP_RATIO_9_16,
              CROP_RATIO_1_1,
              CROP_RATIO_4_5,
            ],
            defaultCropRatio: CROP_RATIO_16_9,
            // some other limitations
          },
          pdf: {
            enabled: true,
            maxFiles: 1,
            maxPageNumbers: 300,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 3000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [YOUTUBE]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        additionalOptions: {
          enabled: true,
          component: YoutubeAdditionalOptions,
        },
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 100,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 60 * 12,
          },
          image: {
            enabled: false,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 3000,
      },
      {
        typeKey: POST_TYPE_VIDEO_KEY,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 100,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60,
            allowedAspectRatios: {
              [VIDEO_MIN_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
              [VIDEO_MAX_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
            },
            // some other limitations
          },
          image: {
            enabled: false,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 3000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [TIKTOK_PERSONAL_ACCOUNT]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 2000,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 10,
            allowedAspectRatios: {
              [VIDEO_MIN_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
              [VIDEO_MAX_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
            },
          },
          image: {
            enabled: true,
            maxFiles: 1,
            maxFileSizeInBytes: 1024 * 1024 * 50,

            cropSuggestions: [CROP_RATIO_16_9, CROP_RATIO_CUSTOM],
            defaultCropRatio: CROP_RATIO_16_9,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 5000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [TIKTOK_BUSINESS_PAGE]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_POST_KEY,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 10,
            allowedAspectRatios: {
              [VIDEO_MIN_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
              [VIDEO_MAX_ASPECT_RATIO]:
                ASPECT_RATIOS_AS_NUMBERS[CROP_RATIO_9_16],
            },
          },
          image: {
            enabled: true,
            maxFiles: 1,
            cropSuggestions: [CROP_RATIO_16_9, CROP_RATIO_CUSTOM],
            defaultCropRatio: CROP_RATIO_16_9,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 5000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [WHATSAPP]: {
    [POST_TYPE_KEY]: [
      {
        typeKey: POST_TYPE_MESSAGE,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 15,
          },
          image: {
            enabled: true,
            maxFiles: MAX_FILE_COUNT,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },

        allowMixedMediaMultiFile: false,
        maxTextLength: 1000,
      },
      {
        typeKey: POST_TYPE_STATUS,
        enabled: true,
        mediaTypes: {
          video: {
            enabled: true,
            maxFiles: 1,
            minDurationInSeconds: 3,
            maxDurationInSeconds: 60 * 30,
          },
          image: {
            enabled: true,
            maxFiles: 1,
            cropSuggestions: [CROP_RATIO_16_9, CROP_RATIO_CUSTOM],
            defaultCropRatio: CROP_RATIO_16_9,
          },
          pdf: {
            enabled: false,
            maxFiles: 1,
          },
        },
        allowMixedMediaMultiFile: false,
        maxTextLength: 1000,
      },
    ].map((postType) => ({
      ...postType,
      title: POST_TYPES_TITLES[postType.typeKey],
      description: POST_TYPE_DESCRIPTIONS[postType.typeKey],
    })),
  },
  [GENERIC]: {
    [POST_TYPE_KEY]: genericPlatformPostTypeConfig,
    [POST_TYPE_OFFER]: genericPlatformPostTypeConfig,
    [POST_TYPE_PRODUCT]: genericPlatformPostTypeConfig,
    [POST_TYPE_INFORMATION_NEWS]: genericPlatformPostTypeConfig,
  },
};

export const showPostTypeSelector = (platform: string) => {
  if (!platformFeatures[platform]) return false;
  return Object.keys(platformFeatures[platform]?.postType).length > 1;
};
