import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import axiosClient from "../../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "@tolgee/react";
import { setPostDataAttribute } from "../../../../redux/slices/lomavisCreator/lomavisCreator";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const TypeaheadLocationSelection = ({ platform, name_key, id_key }) => {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { postData } = useSelector((state: any) => state.lomavisCreator);
  const { [name_key]: name_value, [id_key]: id_value } = useSelector(
    (state: any) => state.lomavisCreator.postData[platform]
  );
  const [searchList, setSearchList] = useState([]); // [ { fb_id: "123", name: "some name" }
  const { place_search_urls } = useSelector(
    (state: any) => state.lomavisCreator.lomavisCreatorConfig
  );
  let debounceTimeout;

  const [isLoading, setIsLoading] = useState(false);

  const searchForPlaces = (query) => {
    setIsLoading(true);
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      const url = place_search_urls[platform];
      const searchUrl = `${url}` + (query.length > 2 ? `?q=${query}` : "");

      axiosClient
        .get(searchUrl)
        .then((res) => {
          const data = res.data.results;
          setSearchList(
            data.map((item) => ({
              fb_id: `${item.id}`,
              name: item.text,
            }))
          );
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching places:", error);
          setSearchList([]);
          setIsLoading(false);
        });
    }, 250);
  };

  return (
    <div>
      <span className="align-items-center">
        <i className="fa-duotone fa-map-marker-alt me-2"></i>
        {t("lomavisCreator.additionalOptions.addLocation")}
        <OverlayTrigger
          placement="top"
          delay={{ show: 100, hide: 100 }}
          overlay={
            <Tooltip>
                <span style={{ display: "block", textAlign: "left" }} className={" text-muted"}>
                    {t("lomavisCreator.additionalOptions.locationSelectionTooltip")}
                </span>
            </Tooltip>
          }
        >
          <i className="fa-duotone fa-circle-info cursor-pointer ms-1"></i>
        </OverlayTrigger>
      </span>
      <Typeahead
        dropup={true} // This will make the dropdown go up
        disabled={!place_search_urls[platform]}
        id="tagged-users"
        labelKey="name"
        positionFixed={true}
        minLength={3}
        selected={id_value ? [{ fb_id: id_value, name: name_value }] : []}
        options={searchList}
        placeholder={t("lomavisCreator.additionalOptions.pleaseSelectLocation")}
        emptyLabel={
          !isLoading ? t("lomavisCreator.additionalOptions.noResultsFound") : ""
        } // Show only when not loading
        isLoading={isLoading}
        onInputChange={(e) => {
          if (e.length > 3) {
            searchForPlaces(e);
          }
        }}
        onChange={(selected) => {
          if (selected.length === 0) {
            // remove selected location
            dispatch(
              setPostDataAttribute({
                platform: platform,
                attribute: id_key,
                value: null,
              })
            );
            dispatch(
              setPostDataAttribute({
                platform: platform,
                attribute: name_key,
                value: null,
              })
            );
            return;
          }

          const selectedItem: any = selected[0];

          dispatch(
            setPostDataAttribute({
              platform: platform,
              attribute: id_key,
              value: selectedItem?.fb_id,
            })
          );
          dispatch(
            setPostDataAttribute({
              platform: platform,
              attribute: name_key,
              value: selectedItem?.name,
            })
          );
        }}
      />
      {!place_search_urls[platform] && (
        <span className="text-muted">
          {t("lomavisCreator.additionalOptions.locationSearchNotAvailable")}
        </span>
      )}
      <div className="d-flex flex-row flex-end">
        <span
          className="text-muted cursor-pointer flex-end"
          onClick={() => {
            dispatch(
              setPostDataAttribute({
                platform: platform,
                attribute: id_key,
                value: null,
              })
            );
            dispatch(
              setPostDataAttribute({
                platform: platform,
                attribute: name_key,
                value: null,
              })
            );
          }}
        >
          {t("lomavisCreator.additionalOptions.clear_selection")}
        </span>
      </div>
    </div>
  );
};
