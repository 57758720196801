export const TIMEOUT = 150;
export const MENTION_TIMEOUT = 200;
export const SHOWM_MAX_PLATFORMS_COUNT = 5;

export const YOUTUBE_CATEGORY_CHOICE_NOT_SET = 0;
export const YOUTUBE_CATEGORY_CHOICE_FILM_ANIMATION = 1;
export const YOUTUBE_CATEGORY_CHOICE_AUTOS_VEHICLES = 2;
export const YOUTUBE_CATEGORY_CHOICE_MUSIC = 10;
export const YOUTUBE_CATEGORY_CHOICE_PETS_ANIMALS = 15;
export const YOUTUBE_CATEGORY_CHOICE_SPORTS = 17;
export const YOUTUBE_CATEGORY_CHOICE_SHORT_MOVIES = 18;
export const YOUTUBE_CATEGORY_CHOICE_TRAVEL_EVENTS = 19;
export const YOUTUBE_CATEGORY_CHOICE_GAMING = 20;
export const YOUTUBE_CATEGORY_CHOICE_VIDEOBLOGGING = 21;
export const YOUTUBE_CATEGORY_CHOICE_PEOPLE_BLOGS = 22;
export const YOUTUBE_CATEGORY_CHOICE_COMEDY = 23;
export const YOUTUBE_CATEGORY_CHOICE_ENTERTAINMENT = 24;
export const YOUTUBE_CATEGORY_CHOICE_NEWS_POLITICS = 25;
export const YOUTUBE_CATEGORY_CHOICE_HOWTO_STYLE = 26;
export const YOUTUBE_CATEGORY_CHOICE_EDUCATION = 27;
export const YOUTUBE_CATEGORY_CHOICE_SCIENCE_TECHNOLOGY = 28;
export const YOUTUBE_CATEGORY_CHOICE_NONPROFITS_ACTIVISM = 29;
export const YOUTUBE_CATEGORY_CHOICE_MOVIES = 30;
export const YOUTUBE_CATEGORY_CHOICE_ANIME_ANIMATION = 31;
export const YOUTUBE_CATEGORY_CHOICE_ACTION_ADVENTURE = 32;
export const YOUTUBE_CATEGORY_CHOICE_CLASSICS = 33;
export const YOUTUBE_CATEGORY_CHOICE_DOCUMENTARY = 35;
export const YOUTUBE_CATEGORY_CHOICE_DRAMA = 36;
export const YOUTUBE_CATEGORY_CHOICE_FAMILY = 37;
export const YOUTUBE_CATEGORY_CHOICE_FOREIGN = 38;
export const YOUTUBE_CATEGORY_CHOICE_HORROR = 39;
export const YOUTUBE_CATEGORY_CHOICE_SCIFI_FANTASY = 40;
export const YOUTUBE_CATEGORY_CHOICE_THRILLER = 41;
export const YOUTUBE_CATEGORY_CHOICE_SHORTS = 42;
export const YOUTUBE_CATEGORY_CHOICE_SHOWS = 43;
export const YOUTUBE_CATEGORY_CHOICE_TRAILERS = 44;
export const YOUTUBE_CATEGORY_CHOICES = [
  { value: YOUTUBE_CATEGORY_CHOICE_NOT_SET, label: "please_choose" },
  { value: YOUTUBE_CATEGORY_CHOICE_FILM_ANIMATION, label: "film_animation" },
  { value: YOUTUBE_CATEGORY_CHOICE_AUTOS_VEHICLES, label: "autos_vehicles" },
  { value: YOUTUBE_CATEGORY_CHOICE_MUSIC, label: "music" },
  { value: YOUTUBE_CATEGORY_CHOICE_PETS_ANIMALS, label: "pets_animals" },
  // { value: YOUTUBE_CATEGORY_CHOICE_SPORTS, label: "sports" },
  // { value: YOUTUBE_CATEGORY_CHOICE_SHORT_MOVIES, label: "short_movies" },
  { value: YOUTUBE_CATEGORY_CHOICE_TRAVEL_EVENTS, label: "travel_events" },
  { value: YOUTUBE_CATEGORY_CHOICE_GAMING, label: "gaming" },
  // { value: YOUTUBE_CATEGORY_CHOICE_VIDEOBLOGGING, label: "videoblogging" },
  { value: YOUTUBE_CATEGORY_CHOICE_PEOPLE_BLOGS, label: "people_blogs" },
  { value: YOUTUBE_CATEGORY_CHOICE_COMEDY, label: "comedy" },
  { value: YOUTUBE_CATEGORY_CHOICE_ENTERTAINMENT, label: "entertainment" },
  { value: YOUTUBE_CATEGORY_CHOICE_NEWS_POLITICS, label: "news_politics" },
  { value: YOUTUBE_CATEGORY_CHOICE_HOWTO_STYLE, label: "howto_style" },
  { value: YOUTUBE_CATEGORY_CHOICE_EDUCATION, label: "education" },
  {
    value: YOUTUBE_CATEGORY_CHOICE_SCIENCE_TECHNOLOGY,
    label: "science_technology",
  },
  {
    value: YOUTUBE_CATEGORY_CHOICE_NONPROFITS_ACTIVISM,
    label: "nonprofits_activism",
  },
  // { value: YOUTUBE_CATEGORY_CHOICE_MOVIES, label: "movies" },
  // { value: YOUTUBE_CATEGORY_CHOICE_ANIME_ANIMATION, label: "anime_animation" },
  // { value: YOUTUBE_CATEGORY_CHOICE_ACTION_ADVENTURE, label: "action_adventure" },
  // { value: YOUTUBE_CATEGORY_CHOICE_CLASSICS, label: "classics" },
  // { value: YOUTUBE_CATEGORY_CHOICE_DOCUMENTARY, label: "documentary" },
  // { value: YOUTUBE_CATEGORY_CHOICE_DRAMA, label: "drama" },
  // { value: YOUTUBE_CATEGORY_CHOICE_FAMILY, label: "family" },
  // { value: YOUTUBE_CATEGORY_CHOICE_FOREIGN, label: "foreign" },
  // { value: YOUTUBE_CATEGORY_CHOICE_HORROR, label: "horror" },
  // { value: YOUTUBE_CATEGORY_CHOICE_SCIFI_FANTASY, label: "scifi_fantasy" },
  // { value: YOUTUBE_CATEGORY_CHOICE_THRILLER, label: "thriller" },
  { value: YOUTUBE_CATEGORY_CHOICE_SHORTS, label: "shorts" },
  // { value: YOUTUBE_CATEGORY_CHOICE_SHOWS, label: "shows" },
  // { value: YOUTUBE_CATEGORY_CHOICE_TRAILERS, label: "trailers" },
];
export const YOUTUBE_PRIVACY_STATUS_PUBLIC = "public";
export const YOUTUBE_PRIVACY_STATUS_UNLISTED = "unlisted";
export const YOUTUBE_PRIVACY_STATUS_PRIVATE = "private";
export const YOUTUBE_PRIVACY_STATUS_CHOICES = [
  { value: YOUTUBE_PRIVACY_STATUS_PUBLIC, label: "public" },
  { value: YOUTUBE_PRIVACY_STATUS_PRIVATE, label: "private" },
  { value: YOUTUBE_PRIVACY_STATUS_UNLISTED, label: "unlisted" },
];

export const YOUTUBE_AVAILABLE_FOR_KIDS_NOT_SET_CHOICE = 0;
export const YOUTUBE_AVAILABLE_FOR_KIDS_CHOICE = 1;
export const YOUTUBE_NOT_AVAILABLE_FOR_KIDS_CHOICE = 2;

export const YOUTUBE_AVAILABLE_FOR_KIDS_CHOICES = [
  { value: YOUTUBE_AVAILABLE_FOR_KIDS_NOT_SET_CHOICE, label: "please_choose" },
  { value: YOUTUBE_AVAILABLE_FOR_KIDS_CHOICE, label: "available_for_kids" },
  {
    value: YOUTUBE_NOT_AVAILABLE_FOR_KIDS_CHOICE,
    label: "not_available_for_kids",
  },
];

export const GOOGLE_CTA_TYPE_CHOICE_BOOK = "book";
export const GOOGLE_CTA_TYPE_CHOICE_ORDER = "order";
export const GOOGLE_CTA_TYPE_CHOICE_SHOP = "shop";
export const GOOGLE_CTA_TYPE_CHOICE_LEARN_MORE = "learn_more";
export const GOOGLE_CTA_TYPE_CHOICE_SIGN_UP = "sign_up";
export const GOOGLE_CTA_TYPE_CHOICE_CALL = "call";
export const GOOGLE_CTA_TYPE_CHOICES = [
  null,
  GOOGLE_CTA_TYPE_CHOICE_BOOK,
  GOOGLE_CTA_TYPE_CHOICE_ORDER,
  GOOGLE_CTA_TYPE_CHOICE_SHOP,
  GOOGLE_CTA_TYPE_CHOICE_LEARN_MORE,
  GOOGLE_CTA_TYPE_CHOICE_SIGN_UP,
  GOOGLE_CTA_TYPE_CHOICE_CALL,
];

export const COMPACT_MODE_HEIGHT = 500;
