import "quill/dist/quill.snow.css"; // Import Quill stylesheet first
import { useEffect, useRef, useState } from "react";
import Quill from "quill"; // Import Quill
import "../../../communication/utils/quilMention.js";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "@tolgee/react";
import {
  QUILL_API_ACTION_ADD_EMOJI,
  getUrlPreview,
  highlightQuillInput,
  setCursorPositionForQuill,
} from "./config";
import {
  setPlatformFirstComment,
  updatePostText,
} from "../../../../redux/slices/lomavisCreator/lomavisCreator";
import axios from "axios";
import { MENTION_TIMEOUT, TIMEOUT } from "../contants";
import EmojiPicker from "../../../communication/messages/emojiPicker/empjiPicker.jsx";

const FirstCommentQuillEditor = ({ platform, showEmojiPicker = true }) => {
  const addEmoji = (emoji) => {
    quillEditor.insertText(
      quillEditor.getSelection(true).index,
      emoji,
      QUILL_API_ACTION_ADD_EMOJI
    );
    quillEditor.setSelection(
      quillEditor.getSelection(true).index + (emoji.length || 2) + 1
    );
  };
  const locationDetails = useSelector((state: any) => state.locationDetails);
  const Quillref = useRef(null);
  const [quillEditor, setQuillEditor] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslate();
  let timeoutId;
  let controller;

  const getMentionsListAxios = async (platform, searchTerm, signal) => {
    console.log("inside it !");
    try {
      const urls = [
        locationDetails?.platforms_information?.[platform]?.[0]
          ?.search_tags_url,
      ];

      const responses = await Promise.all(
        urls.map((url) =>
          axios
            .get(url, {
              signal,
              params: {
                q: searchTerm,
              },
            })
            .catch((error) => {
              console.error(`Error fetching data from ${url}:`, error);
              return { data: [] }; // Return an empty array in case of error
            })
        )
      );

      // Flatten all response.data lists into one list
      const results = responses.flatMap(({ data }) => data);
      // For test return results duplicated 10 times
      return results;
    } catch (error) {
      console.error("Error in getMentionsListAxios:", error);
      return [];
    }
  };

  const getMention = () => {
    return {
      allowedChars:
        /^[A-Za-zÅÄÖÜåäöü\d.\w-|&+$]*(?:\s[A-Za-zÅÄÖÜåäöü\d.\w-|&+$]+)*$/i,
      mentionDenotationChars: ["@"],
      minChars: 1,
      maxChars: 180,
      isolateCharacter: true,
      showDenotationChar: true,
      spaceAfterInsert: true,
      mentionContainerClass: "z-3 ",
      mentionListStyle: {
        zIndex: 1000,
      },
      mentionListClass: "bg-light-secondary rounded list-unstyled z-3",
      listItemClass: "  ",
      dataAttributes: [
        "id",
        "display_name",
        "denotationChar",
        "link",
        "target",
        "disabled",
        "image_url",
        "iconClass",
        "platform_identifier",
        "platform",
        "platform_css_class",
        "show_denotation_char",
        "is_verified",
      ],
      mentionListStyleOptions:
        "max-height:15em; overflow-y:scroll; margin:0; padding:0;",
      mentionContainerClassOptions: [
        "rounded",
        "bg-light-secondary",
        "mention-top",
      ],
      mentionContainerStyleOptions:
        "padding: 5px 3px; position: absolute; visibility: visible; top: 31px; left: 16px; z-index:9999;",
      renderItem: function (item) {
        let iconColorClass = "text-warning";
        let verifiedText = t("lomavisCreator.Quill.Mention.NotVerified");
        let verificationTooltip = t(
          "lomavisCreator.Quill.Mention.notVerifiedToolTip"
        );
        if (item.is_verified) {
          iconColorClass = "text-success";
          verifiedText = t("lomavisCreator.Quill.Mention.verified");
          verificationTooltip = t(
            "lomavisCreator.Quill.Mention.verifiedToolTip"
          );
        }
        const element = `<div class="dark d-flex flex-row align-items-center bg-white rounded py-1 px-1 mt-2 mx-2 cursor-pointer"><img src="${item.image_url}" class="w-50px h-50px"><div class="ms-1 d-flex flex-column"><div class="d-flex fw-bolder">${item.display_name}</div><div class="w-100"><small data-bs-toggle-old="tooltip" data-bs-placement="top" data-bs-title="${verificationTooltip}"><i class="fas fa-check-circle fa-1x ${iconColorClass} px-2"></i> ${verifiedText}</small></div></div><div class="d-flex flex-grow-1 justify-content-end"><i class="fab ${item.platform_css_class} ms-1 text-primary"></i></div></div>`;
        return element;
      },
      renderLoading: function () {
        return '<div class="d-flex"><div class="spinner-border text-primary spinner-border-sm" role="status"></div></div>';
      },
      source: async function (searchTerm, renderList, mentionChar) {
        // Clear the previous timeout
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        // Cancel the previous request if it exists
        if (controller) {
          controller.abort();
        }

        // Create a new AbortController instance
        controller = new AbortController();

        // Set a new timeout
        timeoutId = setTimeout(async () => {
          try {
            const mentions = await getMentionsListAxios(
              platform,
              searchTerm,
              controller.signal
            );
            renderList(mentions, searchTerm);
          } catch (error) {
            console.log("Error in source:", error);
          }
        }, MENTION_TIMEOUT);
      },
    };
  };

  useEffect(() => {
    const modules = {
      toolbar: false,
      mention: getMention(),
    };

    const editor: any = new Quill(Quillref.current, {
      theme: "snow",
      readOnly: false,
      modules,
    });
    editor.container.dataset.platform = platform;

    setQuillEditor(editor);

    return () => {
      editor?.off("text-change");
    };
  }, []);

  useEffect(() => {
    if (!quillEditor) {
      return;
    }
    // add listener for text change for platforms
    quillEditor.on("text-change", function (delta, oldDelta, source) {
      let markHashtagTimeout = null;
      let actionTriggeredByUser =
        source === "user" || source === QUILL_API_ACTION_ADD_EMOJI;
      if (actionTriggeredByUser) {
        //setCursorPositionForQuill(quillInstance)

        if (markHashtagTimeout) {
          clearTimeout(markHashtagTimeout);
          markHashtagTimeout = undefined;
        }

        markHashtagTimeout = setTimeout(function () {
          highlightQuillInput(quillEditor);
          const text = quillEditor.getText();
          Promise.resolve(getUrlPreview(text)).then((urlPrev) => {
            // update text on the slice of the text
            dispatch(
              setPlatformFirstComment({
                platform: platform,
                firstComment: quillEditor.getContents(),
              })
            );
          });
        }, TIMEOUT);
      }
    });

    quillEditor.on("selection-change", function (range, oldRange, source) {
      let actionTriggeredByUser = source === "copyPaste";
      if (actionTriggeredByUser) {
        setCursorPositionForQuill(quillEditor);
      }
    });

    return () => {
      quillEditor.off("text-change");
    };
  }, [quillEditor]);

  return (
    <div>
      <div
        className="custom-editor overflow-visible w-100  border rounded ql-container"
        ref={Quillref}
        style={{
          minHeight: 100,
        }}
      ></div>
      {showEmojiPicker && <EmojiPicker addEmoji={addEmoji} />}
    </div>
  );
};

export default FirstCommentQuillEditor;
