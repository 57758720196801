import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { getLanguage } from "./locales/i18n";
import Router from "./routes";
import { BrowserRouter } from "react-router-dom";

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/database";

import { Tolgee, DevTools, TolgeeProvider, FormatSimple } from "@tolgee/react";
import { UI } from "@tolgee/ui";
import { Toaster } from "react-hot-toast";
import Modal from "./components/modals";
import GlobalLoader from "./components/globalLoader";

function App() {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(FormatSimple())
    .init({
      apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
      apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
      language: getLanguage(),
      staticData: {
        en: () => import("../i18n/en.json"),
        de: () => import("../i18n/de.json"),
      },
    });
  // sync tolgee language with locale recieved on server
  return (
    <TolgeeProvider
      filesUrlPrefix="../i18n/"
      tolgee={tolgee}
      ui={process.env.REACT_APP_TOLGEE_API_URL ? UI : undefined}
      fallback={<GlobalLoader />}
    >
      <Provider store={store}>
        <Toaster />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </TolgeeProvider>
  );
}

export default App;
