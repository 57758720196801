import React, { useEffect } from "react";
import "./styles.css";
import { useDispatch } from "react-redux";
import { setUploadAreaVisibility } from "../../redux/slices/lomavisCreator/lomavisCreator";

const ModalBody = ({ children, onClose }: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Set body overflow to hidden when modal is mounted
    document.body.style.overflow = "hidden";
    // Clean up function to reset body overflow when modal is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []); // Empty

  useEffect(() => {
    // Set state to true when component mounts
    dispatch(setUploadAreaVisibility({ visible: false }));

    // Set state to false when component unmounts
    return () => {
      dispatch(setUploadAreaVisibility({ visible: true }));
    };
  }, [dispatch]);
  return (
    <div
      style={{
        width: "100vh",
        height: "100wh",
        zIndex: 111,
        top: "0px",
        left: "0px",
      }}
      className="underlay position-fixed w-100 h-100 d-flex justify-content-end overflow-hidden"
    >
      <div
        onClick={() => onClose()}
        style={{
          width: "100vh",
          height: "100wh",
          zIndex: 111,
          top: "0px",
          left: "0px",

          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        className="underlay position-absolute w-100 h-100"
      ></div>{" "}
      <div
        style={{ zIndex: 120, width: "50px", height: "50px" }}
        className="bg-secondary rounded-start rounded-start-2 d-flex justify-content-center align-items-center"
      >
        <i
          onClick={() => onClose()}
          className="fa-regular fa-xmark fs-1 text-black cursor-pointer"
        ></i>
      </div>
      <div style={{ zIndex: 120 }} className="modal-container bg-secondary">
        {children}
      </div>
    </div>
  );
};

export default ModalBody;
